import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { userActions, docsActions, chatActions } from '_actions';
import { SidebarUser } from './SidebarUser';
import { SidebarUserMobile } from './SidebarUserMobile';
import { config } from '_helpers/config';
import { Redirect } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { AlertPopup } from '../../_components/AlertPopup';

import {
  CandidateInfoPage,
  EmployeeAgreement,
  MPN,
  PaycheckDistribution,
  DisabilityStatus,
  CoveredVeteran,
  F8850,
  PayDates,
  I9,
  W4,
  EmployeeRehireForm,
  GearIssueForm,
  AboutTreesHandbook,
  NVTSEmployeeHandbook,
  AuthorizationForDirectDeposit,
  MealPeriodInformationAndWaiver,
} from 'Docs/Forms';
import { Pdf } from 'Docs/Components';
import I9Rehire from 'Docs/Forms/I9-rehire';
import GeneralConsentAndReleaseDoc from 'Docs/Forms/GeneralConsentAndReleaseDoc';
import { ArbitrationAgreement } from 'Docs/Forms/ArbitrationAgreement';
import { toast } from 'react-toastify';
import { docStatusConstants } from '_constants';

class Docs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mobile: {
        screen: 'sidebar',
        enabled: false,
        closeConfirmationModal: false,
      },
      alertPop: false,
      alertPopMessage: '',
      adminMode: false,
      lastPage: 'w-4',
      rehire: this.props.rehire || false,
    };

    this.getActiveForm = this.getActiveForm.bind(this);
    this.getPdf = this.getPdf.bind(this);
    this.renderFormOrPdf = this.renderFormOrPdf.bind(this);
    this.setScreen = this.setScreen.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
    this.toggleCloseConfirmation = this.toggleCloseConfirmation.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.resizeTimeOut = null;
    this.handleResize();
  }

  getPdf() {
    // getting active form
    let lastPage = false;
    let docSlug = this.props.match.params.doc;

    let selectedDoc; // = this.props.docs.userDocs.find((userDoc) => userDoc.slug == doc);
    if (this.state.rehire) {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    if (!selectedDoc) {
      return <Redirect to={{ pathname: `/docs` }} />;
    }

    let params = {
      id: selectedDoc.id,
      uid: this.props?.user?.profile?.data?.hash, // the user who logged in
      signature: this.props.chat.settings.signature,
    };
    // setting up url for pdf
    let url = new URL(`${config.apiUrl}/docs/pdf/${selectedDoc.slug}`);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    this.state.rehire &&
      selectedDoc.slug === this.state.lastPage &&
      (lastPage = true); // for rehire
    !this.state.rehire &&
      selectedDoc.slug === this.state.lastPage &&
      (lastPage = true); // for onboarding
    // this.state.rehire && selectedDoc.slug === "w4" ? (lastPage = true) : selectedDoc.slug == "merchandise-order-form" && (lastPage = true); // checking if last page is reached

    return (
      <Pdf url={url.href} lastPage={lastPage} rehire={this.state.rehire} />
    );
  }

  getActiveForm() {
    let { params } = this.props.match;
    let { doc } = params;

    //if user is not admin and doc status is approved then show only pdf
    const isNotAdmin = this.props.user?.profile?.data?.is_admin === 0;

    let filteredDocs;

    const isGiss = this.props.user?.profile?.data?.user_type_id === 11;

    if (this.props.rehire) {
      filteredDocs = isGiss
        ? this.props?.docs?.userDocs.filter(
            (item) => item.category_slug === 'giss-rehire-documents'
          )
        : this.props?.docs?.userDocs.filter(
            (doc) => doc?.category_slug === 'rehire-documents'
          );
    } else {
      filteredDocs = isGiss
        ? this.props?.docs?.userDocs.filter(
            (item) => item.category_slug === 'giss-onboarding-documents'
          )
        : this.props?.docs?.userDocs.filter(
            (doc) => doc?.category_slug === 'onboarding-documents'
          );
    }
    const selectedDoc = filteredDocs?.find((item) => item.slug === doc);

    if (! selectedDoc) {
      return <Redirect to={{ pathname: `/dashboard` }} />;
    }
    if (selectedDoc.status >= 3 && isNotAdmin) {
      return this.getPdf(doc, filteredDocs);
    }

    if (selectedDoc.version != selectedDoc.docs_type.latest_version) {
      if (selectedDoc.status == docStatusConstants.STARTED_FILLING) {
        toast.error("This form is outdated. Please contact the support team to receive the latest forms.");
      }
      return this.getPdf(doc, filteredDocs)
    }

    switch (doc) {
      case 'candidate-information-page':
        return (
          <CandidateInfoPage
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'candidate-information-page'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'employee-agreement':
        return (
          <EmployeeAgreement
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'employee-agreement'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'general-consent-and-release':
        return (
          <GeneralConsentAndReleaseDoc
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === 'general-consent-and-release'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            doc={selectedDoc}
            docs={this.props.docs}
          />
        );
      case 'mpn':
        return (
          <MPN
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'mpn'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'about-trees-employee-handbook-acknowledgement-of-receipt':
        return (
          <AboutTreesHandbook
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={
              this.state.lastPage ===
              'about-trees-employee-handbook-acknowledgement-of-receipt'
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'nvts-employee-handbook-acknowledgement-of-receipt':
        return (
          <NVTSEmployeeHandbook
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={
              this.state.lastPage ===
              'nvts-employee-handbook-acknowledgement-of-receipt'
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'paycheck-distribution-form':
        return (
          <PaycheckDistribution
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'paycheck-distribution-form'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'covered-veteran':
        return (
          <CoveredVeteran
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'covered-veteran'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'disability-status':
        return (
          <DisabilityStatus
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'disability-status'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case '8850':
        return (
          <F8850
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === '8850'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'pay-dates':
        return (
          <PayDates
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'pay-dates'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'i-9':
        return (
          <I9
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'i-9'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'w-4':
        return (
          <W4
            adminMode={this.state.adminMode}
            lastPage={this.state.lastPage === 'w-4'}
            rehire={this.state.rehire}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'gear-issue-form':
        return (
          <GearIssueForm
            lastPage={this.state.lastPage === 'gear-issue-form'}
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'employee-re-hire-form':
        return (
          <EmployeeRehireForm
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={this.state.lastPage === 'employee-re-hire-form'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'authorization-for-direct-deposit': // Removed for NVAM clone
        return (
          <AuthorizationForDirectDeposit
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            lastPage={
              this.state.lastPage === 'authorization-for-direct-deposit'
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'i-9-rehire':
        return (
          <I9Rehire
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === 'i-9-rehire'}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            doc={selectedDoc}
            docs={this.props.docs}
          />
        );
      case 'meal-period-information-and-waiver':
        return (
          <MealPeriodInformationAndWaiver
            adminMode={this.state.adminMode}
            lastPage={
              this.state.lastPage === 'meal-period-information-and-waiver'
            }
            rehire={this.state.rehire}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case 'arbitration-agreement':
        return (
          <ArbitrationAgreement
            adminMode={this.state.adminMode}
            lastPage={
              this.state.lastPage === 'arbitration-agreement'
            }
            rehire={this.state.rehire}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );

      default:
        return <Redirect to={{ pathname: `/dashboard` }} />;
    }
  }

  toggleErrorPopup(errorMsg) {
    this.setState({
      alertPop: true,
      alertPopMessage: errorMsg,
    });
  }

  setScreen(screen) {
    this.setState({ mobile: { ...this.state.mobile, screen } });
  }

  handleResize() {
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(() => {
      if (isMobileOnly && window.screen.width < 768) {
        this.setState({ mobile: { ...this.state.mobile, enabled: true } });
      } else {
        this.setState({ mobile: { ...this.state.mobile, enabled: false } });
      }
    }, 100);
  }

  closeConfirmation(goBack) {
    if (goBack) {
      this.setState({
        mobile: {
          ...this.state.mobile,
          screen: 'sidebar',
          closeConfirmationModal: false,
        },
      });
    } else {
      this.setState({
        mobile: { ...this.state.mobile, closeConfirmationModal: false },
      });
    }
  }

  toggleCloseConfirmation(flag) {
    this.setState({
      mobile: { ...this.state.mobile, closeConfirmationModal: flag },
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('document-active');
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    let { mobile } = this.state;
    if (mobile.enabled && mobile.screen === 'document') {
      document.body.classList.add('document-active');
    } else {
      document.body.classList.remove('document-active');
    }
    const shouldFetchFormOptions = prevState?.rehire !== this?.state?.rehire;
    if (shouldFetchFormOptions) {
      this.props.dispatch(docsActions.getFormOptions(this?.state?.rehire));
    }
  }

  componentDidMount() {
    this.props.dispatch(chatActions.getConversationSettings());
    this.setState({ loading: true });
    this.props.dispatch(docsActions.getFormOptions(this?.state?.rehire));
    this.props.dispatch(userActions.getAll());
    this.props.dispatch(docsActions.getDocs()).then((res) => {
      if (res.success) {
        if (this.props.match && this.props.match.params.doc) {
          this.setState({
            mobile: { ...this.state.mobile, screen: 'document' },
            loading: false,
          });
        } else {
          res.success && this.setState({ loading: false });
        }
      }
    });

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    let category_id,
      user = null,
      slug = 'w-4';

    nextProps.user &&
      nextProps.user.items &&
      nextProps.user.items.data &&
      (user = nextProps.user.items.data);

    user && nextProps.rehire ? (category_id = 2) : (category_id = 1);

    nextProps.docs &&
      nextProps.docs.userDocs.forEach((doc) => {
        doc.docs_categories_id == category_id && (slug = doc.slug);
      });

    user &&
      nextProps.docs.userDocs &&
      this.setState({
        lastPage: slug,
        rehire: nextProps.rehire,
        user,
      });
  }

  renderFormOrPdf() {
    if (
      (this.props?.rehire &&
        this.props?.user?.profile?.data?.rehire_docs_status >= 2) ||
      (!this.props.rehire && this.props?.user?.profile?.data?.docs_status >= 2)
    ) {
      return this.getPdf();
    }
    return this.getActiveForm();
  }

  render() {
    let user;
    this.props.user &&
      this.props.user.items &&
      this.props.user.items.data &&
      (user = this.props.user.items.data);

    let { loading, mobile, alertPop, alertPopMessage } = this.state;

    return (
      <Fragment>
        {this.state.mobile.screen === 'sidebar' || mobile.enabled == false ? (
          <div className="breadcrumbs">
            <span
              onClick={() => {
                this.props.history.push('/');
              }}
              className="green"
            >
              Dashboard
            </span>{' '}
            / Onboarding Documents
          </div>
        ) : (
          ''
        )}

        <section
          className={`user-docs user-onboarding ${
            mobile.enabled && 'mobile-mode'
          }`}
        >
          {alertPop && (
            <AlertPopup
              head="Error"
              message={alertPopMessage}
              close={() => {
                this.setState({ alertPop: false });
              }}
            />
          )}
          {!this.props.rehire && !isMobileOnly && (
            <h1 className="page_title">Onboarding Documents</h1>
          )}
          {this.props.rehire && !isMobileOnly && (
            <h1 className="page_title">Rehire Documents</h1>
          )}

          <div className="onboarding-docs">
            {!loading && (
              <section className="d-flex w-100">
                {mobile.enabled ? (
                  /* Depreciated  */
                  // <NoMobileSupport feature='docs' />

                  // Mobile View
                  <div className="doc_info">
                    {/* SIDEBAR */}
                    {this.state.mobile.screen === 'sidebar' &&
                      !this.state.loading && (
                        <SidebarUserMobile
                          rehire={this.state.rehire}
                          setScreen={this.setScreen}
                        />
                      )}

                    {/* DOCUMENT */}
                    {!this.state.loading &&
                      this.state.mobile.screen === 'document' &&
                      this.getActiveForm()}
                  </div>
                ) : (
                  <div className="doc_info">
                    {!this.state.loading && (
                      <SidebarUser rehire={this.state.rehire} />
                    )}
                    {!this.state.loading && user && this.renderFormOrPdf()}
                  </div>
                )}
              </section>
            )}
          </div>
        </section>
        {/* Modals */}
        <ReactModal
          isOpen={this.state.mobile.closeConfirmationModal}
          onClose={this.closeConfirmation}
          overlayClassName="RemoveApplntPopup"
          contentLabel="Confirmation"
          className="custom-modal"
        >
          <div className="modal-head">
            <h4 className="modal-title">Are you sure you want to leave?</h4>
            <figure>
              <img
                alt="close"
                className="closeModal"
                onClick={this.closeConfirmation}
                src={`${config.assetUrl}icons/close.svg`}
              />
            </figure>
          </div>
          <div className="modal-body">
            <div className="content">
              <p> If you leave before saving, your changes will be lost.</p>
            </div>
            <div className="buttons">
              <button
                className="button grey"
                onClick={() => this.closeConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => this.closeConfirmation(true)}
              >
                Yes, leave without saving
              </button>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, docs, chat } = state;
  return { user, docs, chat };
}

let connectedDocs = connect(mapStateToProps)(Docs);
export { connectedDocs as Docs };
